import { Card, Image, Row, Col, Button } from 'react-bootstrap';
import './index.css';

const CustomCard = ({
  img,
  title,
  text,
  link
}) => {
  return (
    <Card className='cardStyle'>
        <Card.Body>
            <Row>
                <Col>
                    <Card.Title className='mx-5 my-5 card-title'>
                        {title}
                    </Card.Title>
                    <Card.Text className='mx-5 my-5 card-text'>
                        {text}
                    </Card.Text>
                    <Button className='mx-5 my-5 card-text' href={link}>Clique aqui para acessar</Button>
                </Col>
                <Col>
                    <Image
                        className='mt-5 mx-auto d-block'
                        src= {img}
                        fluid
                    />
                </Col>
            </Row>
        </Card.Body>
    </Card>
  );
};

export default CustomCard;
