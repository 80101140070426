import './App.css';
import {Container, Row, Image, Col} from 'react-bootstrap';
import labs from './assets/labs.png'
import fabulista from './assets/fabulista.png'
import noel from './assets/papai-noel.png'
import ra from './assets/natal-ra.png'
import imitai from './assets/imitai.png'
import CustomCard from './components/Card';

function App() {
  return (
    <Container>
      <Row className='justify-content-center mt-4 mb-4'>
        <Col>
          <Image
          src= {labs}
          fluid
          />
        </Col>
      </Row>
      <Row className='justify-content-center mt-4 mb-4'>
        <Col>
          <CustomCard
          title={'Fabulista'}
          text={'Desenvolva sua própria narrativa infantil utilizando a inteligência artificial. Permita que a tecnologia guie sua criatividade, transformando seus personagens e trama em uma experiência única e envolvente. Com a ajuda da IA, sua história ganhará vida de maneira inovadora, proporcionando momentos mágicos e educativos para as crianças. Deixe a imaginação fluir enquanto a tecnologia aprimora sua capacidade de contar histórias, proporcionando um universo cativante e personalizado para os pequenos leitores.'}
          link={'https://fabulista.pitang.com/'}
          img={fabulista}
          />
        </Col>
      </Row>
      <Row className='justify-content-center mt-4 mb-4'>
        <Col>
          <CustomCard
          title={'Papai Noel Virtual'}
          text={'Encante as crianças com uma carta ou vídeo totalmente personalizado do Papai Noel! Deixe a magia do Natal se manifestar de maneira única, trazendo sorrisos e alegria aos rostinhos curiosos. Imagine a expressão de encantamento ao verem seus nomes, feitos incríveis e momentos especiais mencionados pelo próprio Papai Noel. Crie uma experiência mágica e inesquecível, onde a magia do Natal se entrelaça com a tecnologia para proporcionar momentos de pura felicidade. Faça parte dessa magia e surpreenda as crianças com um toque personalizado do bom velhinho!'}
          link={'https://papai-noel-virtual.web.app/'}
          img={noel}
          />
        </Col>
      </Row>
      <Row className='justify-content-center mt-4 mb-4'>
        <Col>
          <CustomCard
          title={'Foto com o Noel com RA'}
          text={'Capture um momento mágico e inesquecível tirando uma foto com realidade aumentada ao lado do Papai Noel e suas renas! Deixe a magia do Natal se materializar em sua própria cena personalizada, onde a tecnologia de realidade aumentada traz o espírito festivo diretamente para o seu mundo. Assista maravilhado enquanto o bom velhinho e suas renas ganham vida na sua foto, criando uma lembrança encantadora que combina a tradição natalina com a inovação tecnológica. Transforme suas fotos em uma experiência única e compartilhe a alegria do Natal com essa adorável adição digital!'}
          link={'https://fotodenatal-ra.web.app/'}
          img={ra}
          />
        </Col>
      </Row>
      <Row className='justify-content-center mt-4 mb-4'>
        <Col>
          <CustomCard
          title={'Imitai'}
          text={'Estabeleça uma conversa com a personalidade que você escolher, fornecendo algumas de suas falas distintivas, e eu irei emular o estilo de linguagem dessa pessoa. Ofereço a capacidade de imitar padrões de fala, absorvendo e refletindo as características marcantes da personalidade desejada. Explore a experiência única de interagir com uma versão simulada da sua personalidade favorita, permitindo-me criar respostas que capturam, na medida do possível, o tom e o estilo específicos que você procura.'}
          link={'https://imitai.web.app/'}
          img={imitai}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;